<template>
  <fieldset v-if="$can.view($store.getters.getAuthUser, 'add-directors')">
    <legend v-if="!!Object.getOwnPropertyDescriptor(director,'id')">
      Edit Director to {{ company.name }}
    </legend>
    <legend v-else>
      Add Director to {{ company.name }}
    </legend>
    <div v-html="$error.handle(error)" />
    <form
      @submit.prevent="submit"
    >
      <div class="row">
        <div
          class="col-md-4 form-group"
          :class="{ 'has-feedback has-error': errors.has('first_name')}"
        >
          <label>First Name</label>
          <input
            v-model="director.firstname"
            v-validate="'required'"
            type="text"
            name="first_name"
            class="form-control"
          >
          <small
            v-if="errors.has('first_name')"
            class="help-block"
          >
            {{ errors.first('first_name') }}
          </small>
        </div>
        <div class="col-md-4 form-group">
          <label>Middle Name</label>
          <input
            v-model="director.middlename"
            type="text"
            class="form-control"
          >
        </div>
        <div
          class="col-md-4 form-group"
          :class="{ 'has-feedback has-error': errors.has('last_name')}"
        >
          <label>Last Name</label>
          <input
            v-model="director.lastname"
            v-validate="'required'"
            type="text"
            name="last_name"
            class="form-control"
          >
          <small
            v-if="errors.has('last_name')"
            class="help-block"
          >
            {{ errors.first('last_name') }}
          </small>
        </div>

        <div
          class="form-group col-md-6"
          :class="{ 'has-feedback has-error': errors.has('phone_number')}"
        >
          <label>Phone Number</label>
          <input
            v-model="director.phone_number"
            v-validate="'required|numeric|digits:12'"
            type="tel"
            class="form-control"
            name="phone_number"
          >
          <small
            v-if="errors.has('phone_number')"
            class="help-block"
          >
            {{ errors.first('phone_number') }}
          </small>
        </div>
        <div
          class="form-group col-md-6"
          :class="{ 'has-feedback has-error': errors.has('email')}"
        >
          <label>Email</label>
          <input
            v-model="director.email"
            v-validate="'required|email'"
            type="text"
            class="form-control"
            name="email"
          >
          <small
            v-if="errors.has('email')"
            class="help-block"
          >
            {{ errors.first('email') }}
          </small>
        </div>
        <div
          class="form-group col-md-6"
          :class="{ 'has-feedback has-error': errors.has('id_number')}"
        >
          <label>ID Number</label>
          <input
            v-model="director.id_number"
            v-validate="'required'"
            type="text"
            class="form-control"
            name="id_number"
          >
          <small
            v-if="errors.has('id_number')"
            class="help-block"
          >
            {{ errors.first('id_number') }}
          </small>
        </div>
        <template v-if="!Object.prototype.hasOwnProperty.call(director,'id')">
          <div
            class="col-md-6 form-group"
            :class="{ 'has-feedback has-error': errors.has('citizenship')}"
          >
            <label>Citizenship</label>
            <select
              v-model="director.citizenship"
              v-validate="'required'"
              name="citizenship"
              class="form-control"
            >
              <option value="kenyan">
                Kenyan
              </option>
              <option value="foreigner">
                Foreigner
              </option>
            </select>
            <small
              v-if="errors.has('citizenship')"
              class="help-block"
            >
              {{ errors.first('citizenship') }}
            </small>
          </div>
          <div class="col-md-6 form-group">
            <label>Directors Photo</label>
            <input
              ref="photo"
              v-validate="'required'"
              name="photo"
              type="file"
              class="form-control"
            >
            <small
              v-if="errors.has('photo')"
              class="help-block"
            >
              {{ errors.first('photo') }}
            </small>
          </div>
          <div class="form-group col-md-6">
            <input
              v-model="terms"
              v-validate="'required'"
              name="terms"
              type="checkbox"
            >
            <label
              for="terms"
              style="margin-left: 10px;"
            >I have read the <a
              href="#"
              @click.prevent="show_terms=true"
            >terms
              and conditions.</a></label>
            <div
              v-if="errors.has('terms')"
              class="help-block"
            >
              {{ errors.first('terms') }}
            </div>
          </div>
        </template> 
    
        <div class="col-md-12 form-group">
          <button
            class="btn btn-primary"
            type="submit"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  </fieldset>
  <div
    v-else
    class="alert alert-warning"
  >
    You are not allowed to add directors to this company.
  </div>
</template>

<script>

export default {
  props: {
   // eslint-disable-next-line vue/require-default-prop
   director: {
       type: Object
  },
  // eslint-disable-next-line vue/require-default-prop
  company: {
       type: Object
  },
 },
  data() {
    return {
      error: '',
    }
  },
  methods: {
    getFormData() {
      const fd = new FormData();
      if (!Object.prototype.hasOwnProperty.call(this.director,'id')) {
        fd.append('photo', this.$refs.photo.files[0]);
        fd.append('company_id', this.director.company_id);
        fd.append('citizenship', this.director.citizenship);
      }
      fd.append('firstname', this.director.firstname);
      fd.append('middlename', this.director.middlename);
      fd.append('lastname', this.director.lastname);
      fd.append('email', this.director.email);
      fd.append('phone_number', this.director.phone_number);
      fd.append('id_number', this.director.id_number);
      return fd
    },
    submit() {
      return this.$validator.validateAll().then(result => {
        if (result) {
          if (Object.prototype.hasOwnProperty.call(this.director, "id")){
            this.updateDirector();
          }else{
            this.postDirector();
          }
        }
      });
    },
    postDirector() {
      this.$axios.post('/api/v1/director', this.getFormData(), {headers: {'content-type': 'multipart/form-data'}}).then((resp) => {
        this.$toastr.s(resp.data);
      }).catch(error => {
        this.error = error.response;
      });
    },
    updateDirector() {
      this.$axios.post('/api/v1/director/'+ this.director.id, this.getFormData(), {headers: {'content-type': 'multipart/form-data'}}).then((resp) => {
        this.$toastr.s(resp.data);
      }).catch(error => {
        this.error = error.response;
      });
    },
  }
}
</script>