<template>
  <panel noButton="true" title="COMPANY DETAILS">
    <div v-if="$can.view($store.getters.getAuthUser, 'view-companies')"
         class="row"
    >
      <div class="col-md-12 mb-2">
        <template v-if="company.approval_status === 'pending'">
          <button
              v-if="$can.view($store.getters.getAuthUser, 'approve-companies')"
              class="btn btn-success btn-sm"
              type="button"
              @click.prevent="adminApproval"
          >
            <i class="fa fa-check" /> Approve
          </button>
        </template>
        <template
            v-if="company.approval_status === 'pending' || $can.view($store.getters.getAuthUser, 'approve-companies')">
          <router-link
              v-if="$can.view($store.getters.getAuthUser, 'add-directors')"
              :to="{ name: 'companies.directors.create', params: { id: this.$route.params.id }}"
              class="btn btn-info btn-sm"
              style="margin-left: 20px"
          >
            <i class="fa fa-user-plus" />
            Add Directors
          </router-link>
        </template>
        <template v-if="$store.getters.getAuthUser.kyc_status && !company.paid">
          <router-link
              :to="{ name: 'companies.payment', params: { id: this.$route.params.id }}"
              class="btn btn-info btn-sm"
              style="margin-left:20px"
          >
            <i class="fa fa-money" />
            Pay for company search
          </router-link>
        </template>
        <router-link
            :to="{ name: 'companies.users', params: { id: this.$route.params.id }}"
            class="btn btn-info btn-sm ml-2"
        >
          <i class="fa fa-users" />
          View Users
        </router-link>
        <button
            v-if="set_limit"
            class="btn btn-info btn-sm ml-2"
            @click.prevent="showModal=true"
        >
          <i class="fa fa-cogs" />
          Update Limit
        </button>
        <button
            v-if="$can.view($store.getters.getAuthUser, 'update-rm')"
            class="btn btn-info btn-sm ml-2"
            @click.prevent="showRMModal=true"
        >
          <i class="fa fa-cogs" />
          Update RM/Agent
        </button>
        <router-link
            v-if="company.approval_status === 'approved'"
            :to="{name: 'performance.limits',params: { id: this.$route.params.id }}"
            class="btn btn-info btn-sm ml-2"
        >
          <i class="fa fa-eye" />
          Performance Limits
        </router-link>
        <template v-if="$can.view($store.getters.getAuthUser, 'update-companies')">
          <router-link
              :to="{name: 'companies.cached',params: { crp: company.crp }}"
              class="btn btn-info btn-sm ml-2"
          >
            <i class="fa fa-building" />
            Search Info
          </router-link>
          <button
              class="btn btn-info btn-sm ml-2"
              @click.prevent="showEditModal=true"
          >
            <i class="fa fa-edit" />
            Edit
          </button>
        </template>
        <button
            v-if="$can.view($store.getters.getAuthUser, 'delete-companies')"
            class="btn btn-danger btn-sm ml-2"
            @click.prevent="deleteCompany()"
        >
          <i class="fa fa-trash" />
          Delete
        </button>
      </div>
      <hr>
      <div class="col-md-6">
        <p><b>Name: </b>{{ company.name }}</p>
        <p><b>Registration Number: </b> {{ company.crp }}</p>
        <p><b>Phone Number: </b>{{ company.phone_number }}</p>
        <p><b>KRA PIN: </b>{{ company.kra_pin }}</p>
        <p v-if="$store.getters.getAuthUser.role !== 'agent'">
          <b>Paid Status: </b>{{ company.paid ? 'Paid' : 'Unpaid' }}
        </p>
        <p><b>Limit: </b>KES {{ $number.format(limit) }}</p>
        <p v-if="set_limit">
          <b>Pricing Group: </b>{{ groupname | unslug }}
        </p>
      </div>
      <div class="col-md-6">
        <p><b>Physical Address: </b>{{ company.physical_address }}</p>
        <p><b>Postal Address: </b>{{ company.postal_address }}</p>
        <p><b>Email Address: </b>{{ company.email }}</p>
        <p v-if="$store.getters.getAuthUser.role !== 'agent'">
          <b>Approval Status: </b>{{ company.approval_status }}
        </p>
      </div>
    </div>
    <div
        v-if="view_directors"
        class="row mt-2"
    >
      <div class="col-md-3">
        <div class="nav flex-column nav-pills">
          <a
              :class="show == 1 ? 'active' : ''"
              class="nav-link"
              href="#"
              @click.prevent="show = 1"
          >Directors</a>
          <a
              :class="show == 2 ? 'active' : ''"
              class="nav-link"
              href="#"
              @click.prevent="show = 2"
          >Approvers</a>
          <a
              :class="show == 3 ? 'active' : ''"
              class="nav-link"
              href="#"
              @click.prevent="show = 3"
          >Documents</a>
        </div>
      </div>
      <div class="col-md-9">
        <div class="table-responsive">
          <template v-if="show == 1">
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Phone Number</th>
                <th>Email Address</th>
                <th>ID Number</th>
                <th>Status</th>
                <th />
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(d, i) in company.directors"
                  :key="i"
              >
                <td>{{ i + 1 }}</td>
                <td>{{ d.firstname + ' ' + d.middlename + ' ' + d.lastname }}</td>
                <td>{{ d.phone_number }}</td>
                <td>{{ d.email }}</td>
                <td>{{ d.id_number }}</td>
                <td>{{ ((d.pivot.verified == '1') ? 'Approved' : 'Pending') }}</td>
                <td>
                  <button
                      v-if="$store.getters.getAuthUser.role !== 'customer' || $can.view($store.getters.getAuthUser, 'approve-companies')"
                      class="btn-sm btn-info"
                      @click.prevent="uploadPicModal(d)"
                  >
                    <i class="fa fa-upload" />
                  </button>
                  <button
                      v-if="$can.view($store.getters.getAuthUser, 'update-companies')"
                      class="btn btn-info btn-sm ml-2"
                      @click.prevent="editDirector(d)"
                  >
                    <i class="fa fa-edit" />
                    Edit
                  </button>
                  <button
                      v-if="$can.view($store.getters.getAuthUser, 'update-companies')"
                      class="btn btn-danger btn-sm ml-2"
                      @click.prevent="deleteDirector(d)"
                  >
                    <i class="fa fa-trash" />
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="row">
              <div
                  v-for="(d, i) in company.directors"
                  :key="i"
                  class="col-lg-3 col-md-4 col-xs-6 thumb"
              >
                <template v-if="d.photo">
                  <img
                      :alt="d.firstname"
                      :src="d.photo"
                      class="img-thumbnail"
                  >
                  <div>{{ fullname(d) }}</div>
                </template>
              </div>
            </div>
          </template>
          <template v-if="show == 2">
            <div class="text-right mb-2">
              <a
                  v-if="$can.view($store.getters.getAuthUser, 'approve-companies')"
                  class="btn btn-info btn-sm"
                  href="#"
                  @click.prevent="showCreateApproverModal = true"
              ><i
                  class="fa fa-user-plus"
              /> Create Approver</a>
            </div>
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Phone Number</th>
                <th>ID Number</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(a, i) in company.approvers"
                  :key="i"
              >
                <td>{{ i + 1 }}</td>
                <td>{{ fullname(a) }}</td>
                <td>{{ a.phone_number }}</td>
                <td>{{ a.id_number }}</td>
                <td>
                  <button
                      class="btn btn-sm btn-primary mr-1"
                      @click.prevent="editApprover(a)"
                  >
                    <i class="fa fa-edit" />
                  </button>
                  <button
                      class="btn btn-sm btn-danger"
                      @click.prevent="deleteApprover(a)"
                  >
                    <i class="fa fa-trash" />
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </template>
          <template v-if="show == 3">
            <div class="text-right mb-2">
              <a
                  v-if="$can.view($store.getters.getAuthUser, 'upload-documents')"
                  class="btn btn-info btn-sm"
                  href="#"
                  @click.prevent="showUploadModal = true"
              ><i
                  class="fa fa-upload"
              /> Upload Documents</a>
            </div>
            <modal
                v-if="$can.view($store.getters.getAuthUser, 'upload-documents')"
                :show-modal="showUploadModal"
                @close="showUploadModal = false"
            >
              <template v-slot:header>
                <h5>Upload Documents</h5>
              </template>
              <div v-html="$error.handle(error)" />
              <form @submit.prevent="uploadDocuments">
                <div class="form-group">
                  <label>Select File</label>
                  <input
                      ref="the_document"
                      class="form-control"
                      required=""
                      type="file"
                  >
                </div>
                <div class="form-group">
                  <button class="btn btn-primary">
                    Upload
                  </button>
                </div>
              </form>
            </modal>
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>#</th>
                <th>Document Name</th>
                <th v-if="$can.view($store.getters.getAuthUser, 'download-documents')">
                  Download
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(doc, i) in company.docs"
                  :key="i"
              >
                <td>{{ i + 1 }}</td>
                <td>{{ doc.name }}</td>
                <td v-if="$can.view($store.getters.getAuthUser, 'download-documents')">
                  <button
                      class="btn btn-sm btn-outline-primary"
                      @click.prevent="download(doc)"
                  >
                    Download <i class="fa fa-download" />
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </template>
        </div>
      </div>
    </div>
    <modal
        :show-modal="showModal"
        @close="showModal = false"
    >
      <template v-slot:header>
        <h5>Adjust Company Limit</h5>
      </template>
      <div v-html="$error.handle(error)" />
      <form @submit.prevent="updateLimit">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Limit</label>
              <vue-numeric
                  v-model="limit"
                  class="form-control"
                  type="text"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <button
                  class="btn btn-primary pull-right"
                  type="submit"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </form>
    </modal>
    <modal
        :show-modal="showEditModal"
        @close="showEditModal = false"
    >
      <template v-slot:header>
        <h5>Edit Company</h5>
      </template>
      <edit-form :company="company" />
    </modal>
    <modal
        :show-modal="showEditDirectorModal"
        @close="showEditDirectorModal = false"
    >
      <directory-form
          :company="company"
          :director="director"
      />
    </modal>
    <modal
        :show-modal="showRMModal"
        @close="showRMModal = false"
    >
      <template #header>Update RM/Agent</template>
      <add-rm :company="company" />
    </modal>
    <modal
        :show-modal="showCreateApproverModal"
        @close="showCreateApproverModal = false"
    >
      <template v-slot:header>
        <h5>Create Company Approver</h5>
      </template>
      <create-approver-form
          :company="company"
          :eapprover="null"
      />
    </modal>
    <modal
        :show-modal="showEditApproverModal"
        @close="showEditApproverModal = false"
    >
      <template v-slot:header>
        <h5>Edit Company Approver</h5>
      </template>
      <create-approver-form
          :company="company"
          :eapprover="approver"
      />
    </modal>
    <modal
        :show-modal="showUploadPicModal"
        @close="showUploadPicModal = false"
    >
      <template v-slot:header>
        <h5>Upload Director {{ director.firstname }} Photo</h5>
      </template>
      <div v-html="$error.handle(error)" />
      <form @submit.prevent="uploadPic">
        <div class="form-group">
          <label>Select File</label>
          <input
              ref="pic"
              class="form-control"
              required=""
              type="file"
          >
        </div>
        <div class="form-group">
          <button class="btn btn-primary">
            Upload
          </button>
        </div>
      </form>
    </modal>
  </panel>
</template>

<script>
import helper from "@/mixins/helper";
import EditForm from "@/views/dashboard/companies/Edit.vue";
import CreateApproverForm from "@/views/dashboard/companies/approvers/Create.vue";
import DirectoryForm from '@/views/dashboard/companies/directors/DirectoryForm.vue';
import AddRm from '@/views/dashboard/companies/AddRm.vue';

export default {
  components: {
    EditForm,
    CreateApproverForm,
    DirectoryForm,
    AddRm
  },
  mixins: [helper],
  data() {
    return {
      loading: true,
      company: {},
      approver: {},
      show: 1,
      showUploadModal: false,
      showEditModal: false,
      showCreateApproverModal: false,
      showEditApproverModal: false,
      showRMModal: false,
      showEditDirectorModal: false,
      showUploadPicModal: false,
      showModal: false,
      error: '',
      limit: 0,
      balance: 0,
      groupname: '',
      director: null,
    }
  },
  computed: {
    view_directors() {
      return this.$store.getters.getAuthUser.role !== 'agent' && this.$can.view(this.$store.getters.getAuthUser, 'view-companies')
    },
    set_limit() {
      return this.$can.view(this.$store.getters.getAuthUser, 'update-companies-limit') && this.company.approval_status === 'approved';
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    getPhoto(director) {
      return director.photo ? `${process.env.VUE_APP_API_URL}/api/v1/director/photo/${director.photo}?token=${localStorage.getItem('credible_app_token')}}` : 'https://via.placeholder.com/150?text=No+Photo+Uploaded';
    },
    editDirector(director) {
      this.director = director;
      this.showEditDirectorModal = true;
    },
    fetch() {
      this.$axios.get('/api/v1/companies/' + this.$route.params.id).then(response => {
        this.loading = false;
        this.groupname = response.data.group;
        this.company = response.data.data;
        this.limit = response.data.limit;
        this.balance = response.data.balance;
        this.setDocuments();

        if (this.company.approval_status !== 'approved')
          this.displayMessage();
      });
    },
    updateLimit() {
      this.$axios.post('/api/v1/companies/limit', {
        'company_id': this.company.company_unique_id,
        'limit': this.limit
      }).then(() => {
        this.showModal = false;
        this.fetch();
        this.$toastr.s('Company limit updated');
      }).catch(error => {
        if (error.response) {
          this.error = error.response;
        }
      });
    },
    download(doc) {
      this.$axios({
        url: '/api/v1/companies/attachments',
        method: 'GET',
        responseType: 'blob',
        params: {
          'company_id': this.company.company_unique_id,
          'path': doc.path
        }
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', doc.name + '.pdf');
        document.body.appendChild(fileLink);
        fileLink.click();
      }).catch(error => {
        if (error.response) {
          this.error = error.response;
        }
      });
    },
    deleteCompany() {
      this.$swal({
        title: 'Are you sure?',
        text: "You are about to delete " + this.company.name,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.$axios.delete('/api/v1/companies/' + this.company.id).then(() => {
            this.$toastr.s('Company deleted successfully.', 'Success');
            this.$router.push({name: 'companies.index'});
          });
        }
      })
    },
    deleteDirector(director) {
      this.$swal({
        title: 'Are you sure?',
        text: "You are about to delete director " + director.firstname,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.$axios.post('/api/v1/director/delete', {
            'director_id': director.id,
            'company_id': this.company.id
          }).then((resp) => {
            this.$toastr.s(resp.data);
          }).catch(error => {
            this.$toastr.w(error.response.data.error);
          });
        }
      })
    },
    deleteApprover(a) {
      this.$swal({
        title: 'Are you sure?',
        text: "You are about to delete approver " + a.firstname,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete!'
      }).then((result) => {
        if (result.value) {
          this.$axios.delete('/api/v1/approvers/' + a.id).then(() => {
            this.$toastr.s('Approver deleted successfully.', 'Success');
            this.fetch();
          });
        }
      })
    },
    editApprover(a) {
      this.approver = a;
      this.showEditApproverModal = true;
    },
    uploadPicModal(d) {
      this.showUploadPicModal = true;
      this.director = d;
    },
    uploadPic() {
      const dat = new FormData();
      dat.append('photo', this.$refs.pic.files[0]);
      dat.append('company_id', this.$route.params.id);
      dat.append('director_id', this.director.id);
      this.$axios.post('/api/v1/director/photo', dat, {headers: {'content-type': 'multipart/form-data'}}).then(() => {
        this.$toastr.s('Director ' + this.director.firstname + ' photo uploaded', 'Success');
        this.showUploadPicModal = false;
        this.director = null;
        this.fetch();
      }).catch(error => {
        if (error.response) {
          this.error = error.response;
        }
      });
    },
    setDocuments() {
      this.company.docs = this.company.docs.map(doc => {
        doc.fullPath = process.env.VUE_APP_API_URL + '/' + doc.path;
        return doc;
      })
    },
    uploadDocuments() {
      const myData = new FormData();
      myData.append('company_document', this.$refs.the_document.files[0]);
      myData.append('company_id', this.$route.params.id);

      this.$axios.post('/api/v1/companies/upload', myData, {headers: {'content-type': 'multipart/form-data'}}).then(() => {
        this.$toastr.s('Document uploaded', 'Saved');
        this.showUploadModal = false;
        this.fetch();
      }).catch(error => {
        if (error.response) {
          this.error = error.response;
        }
      });
    },
    checkApproval() {
      this.loading = true;
      this.$axios.post('/api/v1/companies/approval', {
        'crp': this.company.crp,
        'company_id': this.company.id
      }).then(response => {
        this.loading = false;
        this.company.approval_status = response.data.company.approval_status;
      });
    },
    adminApproval() {
      this.loading = true;
      this.$axios.post('/api/v1/companies/approve', {'company_id': this.company.company_unique_id}).then(resp => {
        this.loading = false;
        this.$toastr.s(resp.data);
        this.$router.push({name: 'companies.index'});
      }).catch(error => {
        this.loading = false;
        this.$toastr.e(error.response.message);
      });
    },
    displayMessage() {
      if (this.company.remaining_directors >= 2 && this.company.current_director <= 2) {
        this.$swal(this.company.name, 'Need to add and verify at least 2 director', 'error');
      }
      if (this.company.remaining_directors <= 2 && this.company.current_director < 1) {
        this.$swal(this.company.name, 'Need to add and verify at least 1 director', 'error');
      }
      this.checkApproval();
    }
  },
}
</script>