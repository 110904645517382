<template>
  <div>
    <loading v-if="loading" />
    <template v-else>
      <div v-html="$error.handle(error)" />
      <form
        @submit.prevent="submit"
      >
        <div class="row">
          <input
            v-model="approver.company_id"
            type="hidden"
          >
          <div class="col-md-4 form-group">
            <label>First Name</label>
            <input
              v-model="approver.firstname"
              v-validate="'required'"
              type="text"
              name="first_name"
              class="form-control"
            >
            <small
              v-if="errors.has('first_name')"
              class="help-block"
            >
              {{ errors.first('first_name') }}
            </small>
          </div>
          <div class="col-md-4 form-group">
            <label>Middle Name</label>
            <input
              v-model="approver.middlename"
              type="text"
              class="form-control"
            >
          </div>
          <div class="col-md-4 form-group">
            <label>Last Name</label>
            <input
              v-model="approver.lastname"
              v-validate="'required'"
              type="text"
              name="last_name"
              class="form-control"
            >
            <small
              v-if="errors.has('last_name')"
              class="help-block"
            >
              {{ errors.first('last_name') }}
            </small>
          </div>
          <div class="col-md-4 form-group">
            <label>Phone Number</label>
            <input
              v-model="approver.phone_number"
              v-validate="'required|min:12'"
              placeholder="254..."
              class="form-control"
              name="phone_number"
            >
            <small
              v-if="errors.has('phone_number')"
              class="help-block"
            >
              {{ errors.first('phone_number') }}
            </small>
          </div>
          <div class="col-md-4 form-group">
            <label>ID Number</label>
            <input
              v-model="approver.id_number"
              v-validate="'required'"
              name="id_number"
              type="text"
              class="form-control"
            >
            <small
              v-if="errors.has('id_number')"
              class="help-block"
            >
              {{ errors.first('id_number') }}
            </small>
          </div>
          <div class="col-md-12 form-group">
            <button class="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    company: {
      "required": true,
      "type": Object
    },
    eapprover: {
      "required": false,
      "type": Object
    },
  },
  data() {
    return {
      loading: false,
      approver: {
        company_id: '',
        firstname: '',
        middlename: '',
        lastname: '',
        id_number: '',
        phone_number: '',
      },
      error: ''
    }
  },
  mounted() {
    if (this.eapprover) {
      this.approver = this.eapprover;
    }
  },
  methods: {
    submit() {
      this.approver.company_id = this.company.id;
      return this.$validator.validateAll().then(result => {
        if (result) {
          this.loading = true;
          if (this.eapprover) {
            this.updateApprover();
          } else {
            this.postApprover()
          }
        }
      });
    },
    updateApprover() {
      this.$axios.put('/api/v1/approvers/' + this.eapprover.id, this.approver).then(() => {
        this.$toastr.s('Approver has been updates', 'Success');
        location.reload();
      }).catch(error => {
        this.error = error.response;
      }).then(() => {
        this.loading = false;
      });
    },
    postApprover() {
      this.$axios.post('/api/v1/approvers', this.approver).then(() => {
        this.$toastr.s('Approver has been added', 'Success');
        location.reload();
      }).catch(error => {
        this.error = error.response;
      }).then(() => {
        this.loading = false;
      });
    }
  }
}
</script>