<template>
  <div>
    <div v-html="$error.handle(error)" />
    <form @submit.prevent="validateForm">
      <div class="container">
        <div class="row">
          <div
              class="col-md-12 form-group"
          >
            <label>Company Name* {{ company.name }}</label>
          </div>
          <div class="form-group col-md-6">
            <label>Relationship Manager
              <small class="text-muted">(Optional)</small>
            </label>
            <v-select
                v-model="company.relationship_manager_id"
                :options="rms"
                :reduce="c => c.id"
                name="relationship_manager"
                label="fullname"
                placeholder="Relationship Manager"
            />
          </div>
          <div class="form-group col-md-6">
            <label>Beedee Agent
              <small class="text-muted">(Optional)</small>
            </label>
            <v-select
                v-model="company.beedee_agent_id"
                :options="beedee_agents"
                :reduce="c => c.id"
                name="beedee_agent"
                label="fullname"
                placeholder="Beedee Agent"
            />
          </div>
          <div class="col-md-12 form-group">
            <button class="btn btn-primary pull-right">
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  props: {company: Object},
  data() {
    return {
      error: ''
    }
  },
  computed: {
    ...mapGetters({
      rms: 'getRelationshipManagers',
      beedee_agents: 'getBeedeeAgents'
    })
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      if (!this.rms.length) {
        this.$store.dispatch("fetchRelationshipManagers");
      }
      if (!this.beedee_agents.length) {
        this.$store.dispatch("fetchBeedeeAgents");
      }
    },
    validateForm() {
      return this.$validator.validateAll().then(result => {
        if (result) {
          this.update();
        }
      });
    },
    update() {
      const company = (
          ({
             id,
             relationship_manager_id,
             beedee_agent_id
           }) =>
              ({
                id,
                relationship_manager_id,
                beedee_agent_id
              })
      )(this.company);

      this.$axios.post('/api/v1/companies/rm', company).then(() => {
        this.$toastr.s("Company RM and agent updated successfully");
        this.$router.push({name: 'companies.index'});
      }).catch(err => {
        if (err.response) {
          this.error = err.response;
        }
      });
    },
  }
}
</script>